<template>
  <div>
    <LiefengContent>
      <template v-slot:title>{{ "网格党支部初始化" }}
      <span class="eye" @click.stop="changeEye">
          <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
      </span>
      <span style="margin-left:20px;color:#00bfbf" >{{$route.query.name}}</span></template>
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true">
            <FormItem>

            </FormItem>
            <Button type="primary" style="margin-right:10px" @click="searchBtn">查询</Button>
            <Button type="primary" style="margin-right:10px" @click="openParty">初始化网格信息</Button>
            <!-- <Button type="info" style="margin-right:10px">初始化负责人信息</Button>              -->
        </Form>
      </template>
      <template v-slot:contentArea>
        <LiefengTable
          :talbeColumns="table.talbeColumns"
          :tableData="table.tableData"
          :loading="table.loading"
          :fixTable="true"
          :curPage="table.page"
          :total="table.total"
          :pagesizeOpts="[20, 30, 50, 100]"
          :page-size="table.pageSize"
          @hadlePageSize="hadlePageSize"
        ></LiefengTable>
      </template> 
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import LiefengCascaderCity from '@/components/LiefengCascaderCity'
export default {
  components: { LiefengContent, LiefengTable, LiefengModal ,LiefengCascaderCity},
  data() {
    return {
      search:{
      },
      table: {
                talbeColumns: [
                    {
                        key:'buildingName',
                        align:'center',
                        title:'网格党支部名称'
                    },
                    {
                        key:'alias',
                        align:'center',
                        title:'网格简称'
                    },
                    {
                        key:'manager',
                        align:'center',
                        title:'党组织书记'
                    },
                    {
                        key:'managerMobile',
                        align:'center',
                        title:'党支部书记联系电话'
                    },
                    {
                        key:'syncStatus',
                        align:'center',
                        title:'是否初始化网格',
                        render: (h,params) =>{
                          return h('div',{},params.row.syncStatus && params.row.syncStatus == 1 ? '是' : '否')
                        }
                    },
                    // {
                    //     key:'gridName',
                    //     align:'center',
                    //     title:'是否初始化负责人'
                    // },
                ],
                tableData: [],
                loading: false,
                page: 1,
                total: 0,
                pageSize: 20,
                currentPage:1
           },
      hasSensitive:true,
     
    };
  },
 async created(){
   await this.getList()
  },
  methods: {
    // 方法部分
    //点击初始化网格信息
    openParty(){
      this.$core.openLayerFrame({
         type:2,
         title:'网格党支部初始化拆分',
         content:`/page#/gridinitializationlw?name=${this.$route.query.name}&orgCode=${this.$route.query.orgCode}`,
         area:['100%','100%']
       },()=>{  
          this.table.page = this.table.currentPage
          this.getList()
       })
    },
    // 切换分页事件
    hadlePageSize(val) {
      this.table.page = val.page;
      this.table.pageSize = val.pageSize;
      this.getList();
    },

    // 查询按钮
    searchBtn(){
        this.table.page = 1;
        this.table.pageSize = 20;
        this.getList()
    },
        
    // 选择社区事件
    changeCasader(val){
        this.search.cascaderIdList = val
    },
    // 关闭弹窗事件
    excelModalData(status){
       
    },

    // 打开弹窗事件
    openModal(){
       
    },
    // 接口部分
    getList(){
      this.table.loading = true
      try{
        this.$get('/syaa/pc/sy/user/gridManage/queryPartyManageByPage',{
          importType:7,
          archivesId:'',
          orgCode:this.$route.query.orgCode,
          page:this.table.page,
          pageSize:this.table.pageSize,
          hasSensitive:this.hasSensitive,
          orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
        }).then(res=>{
          this.table.loading = false
          if(res && res.code == 200){
            this.table.tableData = res.dataList
            this.table.total = res.maxCount
            this.table.currentPage = res.currentPage
          }else{
              this.table.loading = false
              this.$Message.error({
                content:'获取数据失败',
                background:true
              })
              return
          }
        })
      }catch(e){
        this.table.loading = false
        this.$Message.error({
          content:'获取数据失败',
          background:true
        })
        return
      }
    },
    changeEye(){
        this.hasSensitive = !this.hasSensitive
        this.getList()
    },

  },
};
</script>

<style lang="less" scoped>

.export-modal{
  /deep/ #modal_contentarea{
        overflow: visible !important;
        height: 270px;
    }
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.eye{
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 0 15px 0 ;
}
</style>